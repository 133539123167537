var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"height",attrs:{"id":"content","role":"main"}},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"container"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble"},[_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home ")])],1),_vm._m(0),_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":{
                name: 'shop',
              }}},[_vm._v("shop ")])],1),_vm._m(1),_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_c('router-link',{attrs:{"to":{
                name: 'categoryProducts',
                params: { slug: _vm.$route.params.category  },
              }}},[_vm._v(" "+_vm._s(_vm.$route.params.category)+" ")])],1),_vm._m(2),_c('li',{staticClass:"breadcrumb-item flex-shrink-0 flex-xl-shrink-1"},[_vm._v(" "+_vm._s(_vm.$route.params.slug)+" ")])])]),(_vm.subCategory.sub_sub_category)?_c('div',{staticClass:"row"},_vm._l((_vm.subCategory.sub_sub_category),function(sub_c,sub_c_idx){return _c('div',{key:sub_c_idx,staticClass:"col-6 col-md-2-custome"},[_c('router-link',{attrs:{"to":{
              name: 'SubSubCategoryProduct',
              params: {
                category: _vm.subCategory.category.slug,
                subcategory: _vm.subCategory.slug,
                slug: sub_c.slug,
              },
            }}},[_c('div',{staticClass:"box"},[_c('span',{staticClass:"box-txt"},[_c('span',{attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(sub_c.name)+" ")])])])],1)}),0):_vm._e()]),_c('div',{staticClass:"container overflow-hidden"},[_c('div',{staticClass:"d-flex justify-content-between border-bottom border-color-1 flex-lg-nowrap flex-wrap border-md-down-top-0 border-md-down-bottView om- mb-3 mt-2"},[(_vm.subCategory)?_c('h3',{staticClass:"section-title section-title__full mb-0 pb-2 font-size-22"},[_vm._v(" "+_vm._s(_vm.subCategory.name)+" ")]):_vm._e()]),_c('products',{attrs:{"products":_vm.products}})],1),_c('InfiniteLoading',{attrs:{"spinner":"waveDots"},on:{"infinite":_vm.allProducts,"distange":function($event){0.5}}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{staticClass:"custom_angle_right_icon",attrs:{"src":require("../../assets/images/product_details_angle_right_icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{staticClass:"custom_angle_right_icon",attrs:{"src":require("../../assets/images/product_details_angle_right_icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{staticClass:"custom_angle_right_icon",attrs:{"src":require("../../assets/images/product_details_angle_right_icon.png"),"alt":""}})])
}]

export { render, staticRenderFns }